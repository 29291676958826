<template>
  <div>
    <h3>Master Tahun</h3>
    <br />
    <CCard>
      <CCardBody>
       <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div
            :class="[
              'col-md-5',
              'ml-auto',
              user.role.is_opd == 0 || user.role.name.toLowerCase() == 'admin'
                ? ''
                : 'offset-md-2',
            ]"
          >
            <div class="row">
              <div
                :class="[
                  'col-md-6',
                  user.role.is_opd == 0 ||
                  user.role.name.toLowerCase() == 'admin'
                    ? ''
                    : 'offset-md-6',
                ]"
              >
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                  </select>
                </div>
              </div>
              <div
                class="col"
                v-if="
                  user.role.is_opd == 0 ||
                  user.role.name.toLowerCase() == 'admin'
                "
              >
                <button
                  class="btn btn-sm btn-primary"
                  @click="create"
                >
                  Tambah Tahun
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :itemsPerPage="5"
          :fields="fields"
          sorter
        >
          <template #report="item">
            <div>
              <td
                style="
                  min-width: 450px;
                  max-width: 500px !important;
                  white-space: normal;
                "
              >
                {{ item.item.report }}
              </td>
            </div>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton
                @click="destroy(item.id)"
                color="danger"
                square
                size="sm"
              >
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="5"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      :title="!isUpdate ? 'Tambah Tahun' : 'Update Tahun'"
      :color="!isUpdate ? 'primary' : 'warning'"
      size="lg"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.tahun"
            label="Tahun"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <template slot="footer">
        <div class="row">
          <button @click="closeModal" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="store" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>

          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/tahun";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      createModal: false,
      exportModal: false,
      exportType: "",
      fields: data.fields,
      isUpdate: false,
      exportDataParams: {},
      items: [],
      opds: [],
      roles: [],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      total: 0,
      form: {
        tahun_detail: []
      },
      params: {
        sorttype: "ASC",
        sortby: "tahun",
        row: 5,
        page: 1,
        role_id: 0,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
    };
  },
  methods: {
    search() {
      if (this.params.keywordexportExcel != "") {
        this.isSearching = true;
        this.getData();
        this.searchOn = this.params.keyword;
        this.params.keyword = "";
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!");
      }
    },
    openModalExcel() {
      this.exportModal = true;
      this.exportType = "Export Excel";
    },

    openModalPDF() {
      this.exportModal = true;
      this.exportType = "Export PDF";
    },
    exportExcel() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("report/exportReport", this.exportDataParams)
        .then((resp) => {
          loading.hide();
          FileSaver.saveAs(
            resp.data,
            "Laporan_Harian_" +
              this.exportDataParams.firstdate +
              "-" +
              this.exportDataParams.lastdate
          );
          this.exportModal = false;
          this.exportDataParams = {};
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },

    exportPDF() {
      this.$router.push({ name: "ExportPDF", query: this.exportDataParams });
    },

    searchOff() {
      this.isSearching = false;
      this.getData();
    },
    getData() {
      console.log(this.params)
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("tahun/getTahun", this.params)
        .then((resp) => {
          this.items = resp.data.data;

          this.total = resp.data.total;
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    create() {
      this.createModal = true;
      this.isUpdate = false;
    },
    store() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("tahun/addTahun", this.form)
        .then((resp) => {
          this.$toast.success("Berhasil menambah Uraian");
          loading.hide();
          this.closeModal();
          this.getData();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    edit(item) {
      this.isUpdate = true;
      this.form = item;
      this.form.id = item.id;
      this.createModal = true;
      this.getOpd();
    },
    update() {
      if (this.isUpdate) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("tahun/updateTahun", {
            id: this.form.id,
            data: this.form,
          })
          .then((resp) => {
            this.$toast.success("Berhasil memperbarui Uraian");
            loading.hide();
            this.closeModal();
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      } else {
        this.$toast.error("Tidak bisa memperbarui Uraian");
      }
    },
    destroy(id) {
      if (confirm("Data akan dihapus !!")) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("tahun/deleteTahun", id)
          .then(() => {
            this.$toast.success("Berhasil menghapus laporan");
            if (this.total == this.params.row + 1) {
              this.page--;
              this.params.page = this.page;
            }
            this.getData();
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      }
    },
    closeModal() {
      this.createModal = false;
      this.isUpdate = false;
      this.form = {
        tahun_detail: []
      };
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          updated_at: this.$moment(item.updated_at).format(
            "dddd, Do MMMM  YYYY, HH:mm"
          ),
          created_at: this.$moment(item.created_at).format("dddd, Do MMMM YYYY"),
        };
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>